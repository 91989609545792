@import "variables";
@import "../../node_modules/bootstrap/scss/bootstrap";

@import "flash-message";
@import "utilities";
@import "toolbar";
@import "social-buttons";

@import "dashboard";
@import "typography";

body {
  overflow: hidden;
  background: $body-bg;

  main{
	display: flex;
	align-items: stretch;
	overflow: hidden;
	height: 100vh;
	width: 100%;
  }
}

.text-important {
  color: $primary;
  font-weight: 600;
}

// Back to top button
#top-btn {
  position: fixed;
  right: 10px;
  bottom: -100px;
  transition: all ease-in-out 0.7s;
  z-index: 1050;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-headings;
}

.cta-group{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: $space-3;
}