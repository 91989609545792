@use 'variables' as vars;

$dashboard-bg: vars.$gray-25;
$header-padding: 24px 32px;
$header-gap: 24px;
$header-bg: vars.$body-bg;

$subtitle-color: vars.$gray-600;

.dashboard-wp {
  max-height: 100vh;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  /*FIXME*/
  &.scrolled {
	.dashboard-header {
	  transform: translate3d(0, -48px, 0);
	}
  }

  .dashboard {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: stretch;
	height: 100%;

	.dashboard-header {
	  display: flex;
	  flex-direction: column;
	  align-items: stretch;
	  padding: $header-padding;
	  gap: $header-gap;
	  background: $header-bg;
	  transition: transform 0.3s;

	  .dashboard-title {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: stretch;
	  }

	  .dashboard-subtitle {
		color: $subtitle-color;
	  }
	}

	.dashboard-content {
	  background: $dashboard-bg;
	  flex: 1;
	}
  }
}