$social-colors : (
	'website': #2780E3,
	'facebook': #3C599F,
	'instagram': #2A5B83,
	'twitter': #55ACEE,
	'google-plus': #DD4C39,
	'youtube': #CC181E,
	'vine': #55BE96,
	'tumblr': #2C4762,
	'android': #97C024,
	'apple': #313131,
	'tripadvisor': #569441,
	'yelp': #C41200,
	'pinterest': #BD2126,
	'amazon': #232f3e,
	'etsy': #f45800,
	'foursquare': #e65778,
	'linkedin': #0077B5,
	'snapchat': #fffc01,
	'twitch': #6441A5,
	'viadeo': #F07355,
	'vimeo': #1a2e3a,
	'airbnb': #ff5a5f,
	'blogger': #f49440
);

.div-social-link {
	margin-top: 1px;
	margin-bottom: 1px;
	display: inline-block;

	&.input-group {
		display: flex;
	}
}

@each $color, $value in $social-colors {
	.btn-#{$color} {
		color: #fff !important;
		background-color: $value;

		&:hover {
			background-color: darken($value, 7.5%);
		}

		&:focus {
			box-shadow: 0 0 0 $input-btn-focus-width rgba($value, .25);
		}
	}
}