@use "../../node_modules/bootstrap/scss/functions" as f;
@use "sass:map";

$enable-deprecation-messages: false;
$enable-important-utilities: false;
$enable-responsive-font-sizes: true;
$font-family-headings: 'Lexend Deca', sans-serif;
$font-family-sans-serif: $font-family-headings; //'Space Grotesk', sans-serif;

$white: #ffffff;
$black: #000000;

/*Primary*/
$primary-25: #F5F8FF;
$primary-50: #EFF4FF;
$primary-100: #D1E0FF;
$primary-200: #B2CCFF;
$primary-300: #84ADFF;
$primary-400: #528BFF;
$primary-500: #2970FF;
$primary-600: #155EEF;
$primary-700: #004EEB;
$primary-800: #0040C1;
$primary-900: #00359E;

/*Success*/
$success-25: #F6FEF9;
$success-50: #F6FEF9;
$success-100: #D1FADF;
$success-200: #A6F4C5;
$success-300: #6CE9A6;
$success-400: #32D583;
$success-500: #12B76A;
$success-600: #039855;
$success-700: #027A48;
$success-800: #05603A;
$success-900: #054F31;

/*Warning*/
$warning-25: #FFFCF5;
$warning-50: #FFFAEB;
$warning-100: #FEF0C7;
$warning-200: #FEDF89;
$warning-300: #FEC84B;
$warning-400: #FDB022;
$warning-500: #F79009;
$warning-600: #DC6803;
$warning-700: #B54708;
$warning-800: #93370D;
$warning-900: #7A2E0E;

/*Danger*/
$danger-25: #FFFBFA;
$danger-50: #FEF3F2;
$danger-100: #FEE4E2;
$danger-200: #FECDCA;
$danger-300: #FDA29B;
$danger-400: #F97066;
$danger-500: #F04438;
$danger-600: #D92D20;
$danger-700: #B42318;
$danger-800: #912018;
$danger-900: #7A271A;

/*Info*/
$info-25: #e8eeff;
$info-50: #e0e8ff;
$info-100: #D8E2FF;
$info-200: #8BA8FF;
$info-300: #8BA8FF;
$info-400: #648BFF;
$info-500: #3D6EFF;
$info-600: #3661E0;
$info-700: #2B4DB3;
$info-800: #234094;
$info-900: #1C3375;

/*Green*/
$green-25: #F6FEF9;
$green-50: #EDFCF2;
$green-100: #D3F8DF;
$green-200: #AAF0C4;
$green-300: #73E2A3;
$green-400: #3CCB7F;
$green-500: #16B364;
$green-600: #099250;
$green-700: #087443;
$green-800: #095C37;
$green-900: #084C2E;

/*Teal*/
$teal-25: #F6FEFC;
$teal-50: #F0FDF9;
$teal-100: #CCFBEF;
$teal-200: #99F6E0;
$teal-300: #5FE9D0;
$teal-400: #2ED3B7;
$teal-500: #15B79E;
$teal-600: #0E9384;
$teal-700: #107569;
$teal-800: #125D56;
$teal-900: #134E48;

/*Blue*/
$blue-25: #F5FAFF;
$blue-50: #EFF8FF;
$blue-100: #D1E9FF;
$blue-200: #B2DDFF;
$blue-300: #84CAFF;
$blue-400: #53B1FD;
$blue-500: #2E90FA;
$blue-600: #1570EF;
$blue-700: #175CD3;
$blue-800: #0040C1;
$blue-900: #00359E;

/*Indigo*/
$indigo-25: #F5F8FF;
$indigo-50: #EEF4FF;
$indigo-100: #E0EAFF;
$indigo-200: #C7D7FE;
$indigo-300: #A4BCFD;
$indigo-400: #8098F9;
$indigo-500: #6172F3;
$indigo-600: #444CE7;
$indigo-700: #3538CD;
$indigo-800: #2D31A6;
$indigo-900: #2D3282;

/*Purple*/
$purple-25: #FAFAFF;
$purple-50: #F4F3FF;
$purple-100: #EBE9FE;
$purple-200: #D9D6FE;
$purple-300: #BDB4FE;
$purple-400: #9B8AFB;
$purple-500: #745AF8;
$purple-600: #6938EF;
$purple-700: #5925DC;
$purple-800: #4A1FB8;
$purple-900: #3E1C96;

/*Pink*/
$pink-25: #FEF6FB;
$pink-50: #FDF2FA;
$pink-100: #FCE7F6;
$pink-200: #FCCEEE;
$pink-300: #FAA7E0;
$pink-400: #F670C7;
$pink-500: #EE46BC;
$pink-600: #DD2590;
$pink-700: #C11574;
$pink-800: #9E165F;
$pink-900: #851651;

/*Orange*/
$orange-25: #FEFAF5;
$orange-50: #FEF6EE;
$orange-100: #FDEAD7;
$orange-200: #F9DBAF;
$orange-300: #F7B27A;
$orange-400: #F38744;
$orange-500: #EF6820;
$orange-600: #E04F16;
$orange-700: #B93815;
$orange-800: #932F19;
$orange-900: #772917;

/*Yellow*/
$yellow-25: #FEFDF0;
$yellow-50: #FEFBE8;
$yellow-100: #FEF7C3;
$yellow-200: #FEEE95;
$yellow-300: #FDE272;
$yellow-400: #FAC515;
$yellow-500: #EAAA08;
$yellow-600: #CA8504;
$yellow-700: #A15C07;
$yellow-800: #854A0E;
$yellow-900: #713B12;

/*Grays*/
$gray-25: #FCFCFC;
$gray-50: #FAFAFA;
$gray-100: #F4F4F5;
$gray-200: #E4E4E7;
$gray-300: #D1D1D6;
$gray-400: #A0A0AB;
$gray-500: #70707B;
$gray-600: #51525C;
$gray-700: #3F3F46;
$gray-800: #26272B;
$gray-900: #18181B;

/*Primaries*/
$primary-map: (
		"25" : $primary-25,
		"50" : $primary-50,
		"100" : $primary-100,
		"200" : $primary-200,
		"300" : $primary-300,
		"400" : $primary-400,
		"500" : $primary-500,
		"600" : $primary-600,
		"700" : $primary-700,
		"800" : $primary-800,
		"900" : $primary-900
);

/*Successes*/
$success-map: (
		"25" : $success-25,
		"50" : $success-50,
		"100" : $success-100,
		"200" : $success-200,
		"300" : $success-300,
		"400" : $success-400,
		"500" : $success-500,
		"600" : $success-600,
		"700" : $success-700,
		"800" : $success-800,
		"900" : $success-900
);

/*Warnings*/
$warning-map: (
		"25" : $warning-25,
		"50" : $warning-50,
		"100" : $warning-100,
		"200" : $warning-200,
		"300" : $warning-300,
		"400" : $warning-400,
		"500" : $warning-500,
		"600" : $warning-600,
		"700" : $warning-700,
		"800" : $warning-800,
		"900" : $warning-900
);

/*Dangers*/
$danger-map: (
		"25" : $danger-25,
		"50" : $danger-50,
		"100" : $danger-100,
		"200" : $danger-200,
		"300" : $danger-300,
		"400" : $danger-400,
		"500" : $danger-500,
		"600" : $danger-600,
		"700" : $danger-700,
		"800" : $danger-800,
		"900" : $danger-900
);

/*Infos*/
$info-map: (
		"25" : $info-25,
		"50" : $info-50,
		"100" : $info-100,
		"200" : $info-200,
		"300" : $info-300,
		"400" : $info-400,
		"500" : $info-500,
		"600" : $info-600,
		"700" : $info-700,
		"800" : $info-800,
		"900" : $info-900
);


/*Green*/
$green-map: (
		"25" : $green-25,
		"50" : $green-50,
		"100" : $green-100,
		"200" : $green-200,
		"300" : $green-300,
		"400" : $green-400,
		"500" : $green-500,
		"600" : $green-600,
		"700" : $green-700,
		"800" : $green-800,
		"900" : $green-900
);

/*Teals*/
$teal-map: (
		"25" : $teal-25,
		"50" : $teal-50,
		"100" : $teal-100,
		"200" : $teal-200,
		"300" : $teal-300,
		"400" : $teal-400,
		"500" : $teal-500,
		"600" : $teal-600,
		"700" : $teal-700,
		"800" : $teal-800,
		"900" : $teal-900
);

/*Blues*/
$blue-map: (
		"25" : $blue-25,
		"50" : $blue-50,
		"100" : $blue-100,
		"200" : $blue-200,
		"300" : $blue-300,
		"400" : $blue-400,
		"500" : $blue-500,
		"600" : $blue-600,
		"700" : $blue-700,
		"800" : $blue-800,
		"900" : $blue-900
);


/*Indigos*/
$indigo-map: (
		"25" : $indigo-25,
		"50" : $indigo-50,
		"100" : $indigo-100,
		"200" : $indigo-200,
		"300" : $indigo-300,
		"400" : $indigo-400,
		"500" : $indigo-500,
		"600" : $indigo-600,
		"700" : $indigo-700,
		"800" : $indigo-800,
		"900" : $indigo-900
);

/*Purples*/
$purple-map: (
		"25" : $purple-25,
		"50" : $purple-50,
		"100" : $purple-100,
		"200" : $purple-200,
		"300" : $purple-300,
		"400" : $purple-400,
		"500" : $purple-500,
		"600" : $purple-600,
		"700" : $purple-700,
		"800" : $purple-800,
		"900" : $purple-900
);

/*Pinks*/
$pink-map: (
		"25" : $pink-25,
		"50" : $pink-50,
		"100" : $pink-100,
		"200" : $pink-200,
		"300" : $pink-300,
		"400" : $pink-400,
		"500" : $pink-500,
		"600" : $pink-600,
		"700" : $pink-700,
		"800" : $pink-800,
		"900" : $pink-900
);

/*Oranges*/
$orange-map: (
		"25" : $orange-25,
		"50" : $orange-50,
		"100" : $orange-100,
		"200" : $orange-200,
		"300" : $orange-300,
		"400" : $orange-400,
		"500" : $orange-500,
		"600" : $orange-600,
		"700" : $orange-700,
		"800" : $orange-800,
		"900" : $orange-900
);

/*Yellows*/
$yellow-map: (
		"25" : $yellow-25,
		"50" : $yellow-50,
		"100" : $yellow-100,
		"200" : $yellow-200,
		"300" : $yellow-300,
		"400" : $yellow-400,
		"500" : $yellow-500,
		"600" : $yellow-600,
		"700" : $yellow-700,
		"800" : $yellow-800,
		"900" : $yellow-900
);

/*Grays*/
$gray-map: (
		"25" : $gray-25,
		"50" : $gray-50,
		"100" : $gray-100,
		"200" : $gray-200,
		"300" : $gray-300,
		"400" : $gray-400,
		"500" : $gray-500,
		"600" : $gray-600,
		"700" : $gray-700,
		"800" : $gray-800,
		"900" : $gray-900
);

$theme-map: (
		"primary" : $primary-map,
		"success" : $success-map,
		"danger" : $danger-map,
		"warning" : $warning-map,
		"info" :$info-map
);

$colors-map: map-merge((
		"green" : $green-map,
		"teal" : $teal-map,
		"blue" : $blue-map,
		"pink" : $pink-map,
		"purple" : $purple-map,
		"indigo" : $indigo-map,
		"orange" : $orange-map,
		"yellow" : $yellow-map,
		"gray" : $gray-map
), $theme-map);


$primary: $primary-500;
$secondary: $gray-500;
$success: $success-500;
$info: $info-500;
$warning: $warning-500;
$danger: $danger-500;
$light: $white;
$dark: $black;

$theme-colors: (
		"primary": $primary,
		"secondary": $secondary,
		"success": $success,
		"info": $info,
		"warning": $warning,
		"danger": $danger,
		"light": $light,
		"dark": $dark
) !default;

$colors: (
	"green" : $green-500,
	"teal" : $teal-500,
	"blue" : $blue-500,
	"pink" : $pink-500,
	"purple" : $purple-500,
	"indigo" : $indigo-500,
	"orange" : $orange-500,
	"yellow" : $yellow-500,
	"gray" : $gray-500,
);

$color-muted: #c0c0c0;
$body-color: $black;
$body-bg: $gray-100;

$grid-breakpoints: (
		xs: 0,
		sm: 576px,
		md: 768px,
		lg: 992px,
		xl: 1200px,
		xxl: 1400px
);


/*shadows*/
$shadow-sm : 0 1px 3px rgba($gray-100, 0.06);
$shadow-md : 0 4px 8px -2px rgba($gray-100, 0.1), 0 2px 4px -2px rgba($gray-100, 0.06);
$shadow-lg:  0 12px 16px -4px rgba($gray-100, 0.08), 0 4px 6px -2px rgba($gray-100, 0.03);
$shadow-xl:  0 20px 24px -4px rgba($gray-100, 0.08), 0 8px 8px -4px rgba($gray-100, 0.03);

/*Use a 4px spacing*/
$space-1 : 4px;
$space-2 : 8px;
$space-3 : 12px;
$space-4 : 16px;
$space-5 : 20px;
$space-6 : 24px;
$space-7 : 28px;
$space-8 : 32px;
$space-10 : 40px;
$space-12 : 48px;
$space-16 : 64px;
$space-32 : 128px;
$space-64 : 256px;
$space-128 : 512px;

$spaces : (
		1 : $space-1,
		2 : $space-2,
		3 : $space-3,
		4 : $space-4,
		5 : $space-5,
		6 : $space-6,
		7 : $space-7,
		8 : $space-8,
		10 : $space-10,
		12 : $space-12,
		16 : $space-16,
);

$border-radius-sm: .2rem;
$border-radius: .25rem;
$border-radius-lg: .3rem;
$border-radius-pill: 50%;



/*local var*/
$title-margin-bottom: 25px;
$section-padding-y: 50px;
$section-padding-x: 1.25rem;
$listing-padding: 25px 0;
$section-gap: 50px;

/*navbar*/
$navbar-height: 70px;
$navbar-bg: transparent;
$navbar-border-color: $color-muted;
$navbar-bg-scrolled: $white;
$navbar-color-scrolled: $white;
$navbar-padding: 5px;
$navbar-index: 1040;
$navbar-color: $primary;

$navbar-content-gap: 8px;

$navbar-item-padding: 12px;

/*Icons*/
$icons-section-width: 90%;
$icons-section-padding-y: 25px;
$icon-card-bg: $white;
$icon-shadow: 0 2px 8px 3px rgba($black, 0.25),
0 4px 2px 0 rgba($black, 0.1);

/*btn*/
$btn-padding: 8px 18px;
$btn-box-shadow-hover: inset 0 0 50px 0 #{rgba(var(--color-rgb), 30%)}, 0 0 5px 2px rgba($white, 0.3);


/*Members*/
$text-muted-on-light: $gray-600;
$text-muted-on-dark: $gray-200;
$text-muted: $text-muted-on-light;
$shadow: 0 0 4px 1px rgba($black, 0.16);


/*Milestone List*/
$fs-sm: 14px;
$fs-lg: 34px;


