// Text utilities
.text-capitalize-first {
  display: inline-block;

  &:first-letter {
	text-transform: uppercase;
  }
}

// Modal
.modal-xxl {
  width: auto !important;
  max-width: 100% !important;
  margin: 10px !important;
}

.modal-xl {
  height: calc(100vh - var(--bs-modal-margin) * 2);
  overflow: hidden;

  .modal-content {
	min-height: 100%;

	.modal-body {
	  overflow-y: auto;
	}
  }
}

// Button
.btn-circle {
  width: 38px !important;
  height: 38px !important;
  border-radius: 50% !important;
  padding: 0 !important;

  svg {
	vertical-align: -2px !important;
  }
}