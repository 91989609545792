// Sy page edition toolbar
#sy-page-toolbar {
	position: fixed;
	left: 10px;
	bottom: 50px;
	z-index: 1050;
}
#sy-page-topbar {
	position: fixed;
	top: 10px;
	right: 0;
	z-index: 1050;
}
#sy-page-bottombar {
	position: fixed;
	bottom: 0;
	z-index: 1050;
}