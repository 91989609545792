@use "variables" as vr;
@use "node_modules/bootstrap/scss/mixins" as mx;

.flash-message {
  left: 150%;
  overflow: hidden;
  position: fixed;
  width: 50%;
  opacity: 0.9;
  z-index: 2000;
  transition: left 0.6s ease-out 0s;
  top: 70px;

  &.in {
	left: 50%;
  }
}

.flash-message h4:empty {
  display: none;
}

.alert {
  padding: vr.$space-4;

  @each $label, $colors in vr.$theme-map {
	&.alert-#{$label} {
	  $bg: map-get($colors, "25");
	  $color: map-get($colors, "700");
	  $border-color: map-get($colors, "300");

	  --bs-alert-color: #{$color};
	  --bs-alert-bg: #{$bg};
	  --bs-alert-border-color: #{$border-color};
	}
  }
}